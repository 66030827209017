import {getCol} from 'firekit'

const userRolesPath = 'userRoles'

export default function getCompanyId(role) {
    if (!role || !role.roles[0]) {
        return ""
    }
    let companyId
    const roleData = role.roles[0]
    for (let key in roleData) {
        if (roleData.hasOwnProperty(key) && roleData[key] === 'admin') {
            companyId = key
            break;
        }
    }
    return companyId
}

export function getCompanyIdFromState(state) {
    const roleData = getCol(state, userRolesPath)
    if (!roleData || !roleData[0]) {
        return ""
    }
    const role = roleData[0].data
    return getCompanyId(role)
}

export function watchForRoles(userId, watchCol, firebaseApp) {
    watchCol(firebaseApp,
        firebaseApp
            .firestore()
            .collection(userRolesPath)
            .where('__name__', '==', userId),
        //.doc(user.uid),
        userRolesPath)
}

export function watchForRolesLocal(userId, watchCol, firebaseApp) {
    watchCol(
        firebaseApp
            .firestore()
            .collection(userRolesPath)
            .where('__name__', '==', userId),
        //.doc(user.uid),
        userRolesPath)
}