import AccountBoxIcon from '@material-ui/icons/AccountBox'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LanguageIcon from '@material-ui/icons/Language'
import LockIcon from '@material-ui/icons/Lock'
import ShopIcon from '@material-ui/icons/Shop'
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HistoryIcon from '@material-ui/icons/History'
import React from 'react'
import StyleIcon from '@material-ui/icons/Style'
import allLocales from './locales'
import allThemes from './themes'

const getMenuItems = (props) => {
  const {
    intl,
    updateLocale,
    locale,
    menuContext,
    themeContext,
    a2HSContext,
    firebaseApp,
    auth: authData,
  } = props
  const {
    isDesktop,
    isAuthMenuOpen,
    useMiniMode,
    setMiniMode,
    setAuthMenuOpen,
  } = menuContext
  const {themeID = 'en', setThemeID} = themeContext || {}
  const {isAppInstallable, isAppInstalled, deferredPrompt} = a2HSContext || {}
  const {auth} = authData
  const {isGranted = () => false} = auth || {}

  const localeItems = allLocales.map((l) => {
    return {
      value: undefined,
      visible: true,
      primaryText: intl.formatMessage({id: l.locale}),
      onClick: () => {
        updateLocale(l.locale)
        if (!isAuthorised) {
          try {
            window.location.reload()
          } catch (error) {
          }
        }
      },
      leftIcon: <LanguageIcon/>,
    }
  })

  const isAuthorised = auth.isAuthenticated

  const themeItems = allThemes.map((t) => {
    return {
      value: undefined,
      visible: true,
      primaryText: intl.formatMessage({id: t.id}),
      onClick: () => {
        setThemeID(t.id)
      },
      leftIcon: <StyleIcon style={{color: t.color}}/>,
    }
  })

  const handleSignOut = () => {
    setAuthMenuOpen(false)
    firebaseApp.auth().signOut()
    localStorage.clear()
  }

  if (isAuthMenuOpen) {
    return [
      {
        value: '/my_account',
        primaryText: intl.formatMessage({
          id: 'my_account',
          defaultMessage: 'My Account',
        }),
        leftIcon: <AccountBoxIcon/>,
      },
      {
        value: '/signin',
        onClick: isAuthorised ? () => handleSignOut() : () => {
        },
        visible: true,
        primaryText: isAuthorised
          ? intl.formatMessage({id: 'sign_out'})
          : intl.formatMessage({id: 'sign_in'}),
        leftIcon: isAuthorised ? <ExitToAppIcon/> : <LockIcon/>,
      },
    ]
  }
  return [
    {
      value: '/signin',
      onClick: isAuthorised ? () => handleSignOut() : () => {
      },
      visible: !isAuthorised,
      primaryText: isAuthorised
        ? intl.formatMessage({id: 'sign_out'})
        : intl.formatMessage({id: 'sign_in'}),
      leftIcon: isAuthorised ? <ExitToAppIcon/> : <LockIcon/>,
    },
    {
      value: '/',
      visible: isAuthorised,
      primaryText: intl.formatMessage({id: 'couriers'}),
      leftIcon: <ShopIcon/>,
    },
    {
      value: '/history',
      visible: isAuthorised,
      primaryText: intl.formatMessage({id: 'history'}),
      leftIcon: <HistoryIcon/>,
    },
    {
      value: '/organization',
      visible: isAuthorised,
      primaryText: intl.formatMessage({id: 'organization'}),
      leftIcon: <GroupWorkIcon/>,
    },
  ]
}
export default getMenuItems
