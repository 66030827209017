import {lazy} from 'react'
import locales from './locales'
import routes from './routes'
import getMenuItems from './menuItems'
import themes from './themes'
import parseLanguages from 'base-shell/lib/utils/locale'
import grants from './grants'
import Loading from 'material-ui-shell/lib/components/Loading/Loading'
import getDefaultRoutes from './getDefaultRoutes'
import {defaultUserData, isGranted} from 'rmw-shell/lib/utils/auth'
import getCompanyId from "../utils/company";

const config = {
  firebase: {
    prod: {
      initConfig: {
        apiKey: 'AIzaSyDS-4J-fiyGi-_g61uofyJU662a4_mxroE',
        authDomain: 'armadaops-com.firebaseapp.com',
        databaseURL: 'https://armadaops-com.firebaseio.com',
        projectId: 'armadaops-com',
        storageBucket: 'armadaops-com.appspot.com',
        messagingSenderId: '715023510847',
        appId: '1:715023510847:web:721c6e9f825839850c778a',
      },
      messaging: {
        publicVapidKey:
          'BNMDPxKbsd2HdjL85WFRy-a-LKQ9C_tfE9aLbJkm3rKsC_U0lNOnQ0DIecqDBxlZ76q_N7I6O1jp4p6ny8Yas_0',
      },
    },
    dev: {
      initConfig: {
        apiKey: 'AIzaSyDS-4J-fiyGi-_g61uofyJU662a4_mxroE',
        authDomain: 'armadaops-com.firebaseapp.com',
        databaseURL: 'https://armadaops-com.firebaseio.com',
        projectId: 'armadaops-com',
        storageBucket: 'armadaops-com.appspot.com',
        messagingSenderId: '715023510847',
        appId: '1:715023510847:web:721c6e9f825839850c778a',
      },
      messaging: {
        publicVapidKey:
          'BNMDPxKbsd2HdjL85WFRy-a-LKQ9C_tfE9aLbJkm3rKsC_U0lNOnQ0DIecqDBxlZ76q_N7I6O1jp4p6ny8Yas_0',
      },
    },
    firebaseuiProps: {
      signInOptions: [
        'google.com',
        // 'facebook.com',
        // 'twitter.com',
        // 'github.com',
        'password',
        // 'phone',
      ],
    },
  },
  googleMaps: {
    apiKey: 'AIzaSyDS-4J-fiyGi-_g61uofyJU662a4_mxroE',
  },
  hereMaps: {
    apiKey: 'x1oiGqKkjKS9mCWVWUE6b_a9Hx9y-qlID9QFhpRu9r0',
  },
  auth: {
    grants,
    redirectTo: '/',
    persistKey: 'base-shell:auth',
    signInURL: '/signin',
    onAuthStateChanged: async (user, auth, firebaseApp) => {
      if (user != null) {
        const companyRolesSnap = await firebaseApp
          .firestore()
          .collection('userRoles')
          .where('__name__', '==', user.uid)
          .get()

        const companyId = (companyRolesSnap.docs && companyRolesSnap.docs[0]) ? getCompanyId(companyRolesSnap.docs[0].data()) : null

        auth.updateAuth({
          ...defaultUserData(user),
          companyId
        })

        firebaseApp.database().ref(`users/${user.uid}`).update({
          displayName: user.displayName,
          uid: user.uid,
          photoURL: user.photoURL,
          providers: user.providerData,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
        })
      } else {
        firebaseApp.database().ref().off()
        auth.setAuth(defaultUserData(user))
      }
    },
  },
  getDefaultRoutes,
  routes,
  locale: {
    locales,
    persistKey: 'base-shell:locale',
    defaultLocale: parseLanguages(['en',], 'en'),
    onError: (e) => {
      //console.warn(e)

      return
    },
  },
  menu: {
    getMenuItems,
    MenuHeader: lazy(() =>
      import('material-ui-shell/lib/components/MenuHeader/MenuHeader')
    ),
  },
  theme: {
    themes,
    defaultThemeID: 'default',
    defaultType: 'light',
  },
  pages: {
    //LandingPage: lazy(() => import('../pages/LandingPage')),
    PageNotFound: lazy(() => import('../pages/PageNotFound')),
  },
  components: {
    Menu: lazy(() =>
      import('rmw-shell/lib/containers/FirebaseMenu/FirebaseMenu')
    ),
    Loading,
  },

  containers: {
    AppContainer: lazy(() =>
      import('material-ui-shell/lib/containers/AppContainer/AppContainer')
    ),
    LayoutContainer: lazy(() =>
      import('rmw-shell/lib/containers/LayoutContainer/LayoutContainer')
    ),
  },
  tracking: {
    dev: {
      url: 'http://localhost:3000'
    },
    prod: {
      url: 'https://tracking.armadaops.com'
    }
  }
}

export default config
