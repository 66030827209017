/* eslint-disable react/jsx-key */
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const About = lazy(() => import('../pages/About'))
const Admin = lazy(() => import('../pages/Demo/Admin'))
const Companies = lazy(() => import('../pages/Demo/Companies'))
const Company = lazy(() => import('../pages/Demo/Companies/Company'))
const Tasks = lazy(() => import('../pages/Demo/Tasks'))
const Task = lazy(() => import('../pages/Demo/Tasks/Task'))
const FirebaseCols = lazy(() => import('../pages/Firebase/Cols'))
const FirebaseDocs = lazy(() => import('../pages/Firebase/Docs'))
const FirebaseLists = lazy(() => import('../pages/Firebase/Lists'))
const FirebaseMessaging = lazy(() => import('../pages/Firebase/Messaging'))
const FirebasePaths = lazy(() => import('../pages/Firebase/Paths'))
const FirebaseStorage = lazy(() => import('../pages/Firebase/Storage'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
const Couriers = lazy(() => import('../pages/Couriers'))
const History = lazy(() => import('../pages/History'))
const Organization = lazy(() => import('../pages/Organization'))
const GettingStarted = lazy(() =>
  import('../pages/Documentation/GettingStarted')
)

const routes = [
  <Route path="/about" exact component={About} />,
  <AuthorizedRoute
    path="/"
    exact
    component={Couriers}
  />,
  <AuthorizedRoute
    path="/history"
    exact
    component={History}
  />,
  <AuthorizedRoute
    path="/organization"
    exact
    component={Organization}
  />,
]

export default routes
